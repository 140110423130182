import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { AboutPage } from "../components/pageLayout/AboutPage";

const About = ({ data }) => {
  // const exhibitions = [...data.solo.edges, ...data.group.edges];
  // const home = data.home;
  return (
    <>
      <Seo title="About Learn HTML" />
      <AboutPage />
    </>
  );
};

export default About;
