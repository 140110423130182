import React from "react";
import { RandomEmoji } from "../../common/RandomEmoji";
import { Link } from "gatsby";

import * as styles from "./AboutPage.module.scss";

const AboutPage = () => {
  return (
    <>
      <div className={styles.homeEmoji}>
        <Link to="/">
          <RandomEmoji />
        </Link>
      </div>
      <div className={styles.about}>
        <h1>About this website</h1>
        <div className={styles.description}>
          <p>
            This site is a work in progress. It will be built upon over the next
            few months to provide a whole bunch of lessons on the web!
          </p>
          <p>
            The aim of this site is to provide lessons that take a graphic
            designer from beginniner through to an active creator of web
            content. Graphic designers design the web, we want to understand how
            it works, so that we can manipulate it. Just as we need to know how
            ink and paper works to design better posters, we need to know how
            HTML and CSS operate to design better web pages.
          </p>
          <p>
            If the designer doesn't know any HTML or CSS then they wont be the
            last designer to affect the web page - so lets learn a little to
            know more.
          </p>
        </div>
      </div>
    </>
  );
};

export { AboutPage };
